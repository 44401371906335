@mixin icon-font-face($font-family, $file-path) {
  font-family: $font-family;
    src: url('#{$file-path}#{$font-family}.eot');
    src: url('#{$file-path}#{$font-family}.eot?#iefix') format('embedded-opentype'),
         url('#{$file-path}#{$font-family}.woff') format('woff'),
         url('#{$file-path}#{$font-family}.ttf') format('truetype'),
         url('#{$file-path}#{$font-family}.svg##{$font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
}

$path: '../fonts/linea/';

@import 'mixins/font-face';
@import 'mixins/appearance';
@import 'icons/basic';
@import 'icons/basic_elaboration';
@import 'icons/arrows';
@import 'icons/ecommerce';
@import 'icons/music';
@import 'icons/software';
@import 'icons/weather';
